import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Drawer, Form, Image, Input, Row, Space, Upload } from "antd";
import toast from "react-hot-toast";
import { PlusOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../utils/utils";
import { API_URL, APP_JOB_BLOG_URL } from "../../../helpers/AppApiUrls";
import QuillEditor from "../../globalComponents/QuillEditor";

const CreateBlogPostDrawer = ({ open, setOpen, onFinish, defaultJobDetail }) => {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState(null);

  const onClose = () => {
    form.setFieldsValue({
      title: null,
      blogImage: [],
      content: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (defaultJobDetail)
      form.setFieldsValue({
        title: defaultJobDetail?.title,
        blogImage: defaultJobDetail?.blog_image_name
          ? [
              {
                uid: defaultJobDetail?.blog_image_name,
                name: defaultJobDetail?.blog_image_name,
                status: "done",
                url: `${API_URL}${APP_JOB_BLOG_URL}/${defaultJobDetail?.blog_image_name}`,
              },
            ]
          : [],
        content: defaultJobDetail?.content,
      });
    // form.setFieldsValue({
    //   categoryId: 1,
    //   locationId: 789,
    //   jobTypeId: 11,
    //   salaryRange: "2500-3000",
    //   expRange: "2-3",
    //   title: "defaultJobDetail?.job_name",
    //   companyName: "defaultJobDetail?.company_name",
    //   gender: "both",
    //   logo: [],
    //   overview: "defaultJobDetail?.overview",
    //   description: "defaultJobDetail?.description",
    //   extra: "defaultJobDetail?.extra",
    //   skill: ["test", "test2"],
    //   benefit: ["test", "test2"],
    //   responsibility: ["test", "test2"],
    //   countryId: 1,
    //   nationalityId: [1],
    // });
  }, [defaultJobDetail]);

  const handleSubmit = () => {
    form.submit();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e?.fileList?.find((element) => element.size / 1024 / 1024 < 5)) {
      return [e?.fileList[0]];
    } else return null;
  };

  const validateRequired = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  });

  const beforeUpload = () => {
    return false;
  };

  const handleFileRemove = async (file) => {
    setFile(null);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  return (
    <>
      <Drawer
        title="Create a new blog post"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
            overflowX: "visible",
          },
        }}
        placement="right"
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        {open && (
          <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={"optional"}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="blogImage"
                  label="Blog image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Upload maxCount={1} beforeUpload={beforeUpload} onPreview={handlePreview} onRemove={handleFileRemove} accept=".jpeg,.jpg,.png,.svg" listType="picture-card">
                    {file ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter blog title",
                    },
                  ]}
                >
                  <Input placeholder="Please enter blog title" />
                </Form.Item>
              </Col>
            </Row>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none",
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="content"
                  label="Content"
                  rules={[
                    {
                      required: true,
                      message: "Please write your blog content",
                    },
                  ]}
                >
                  <QuillEditor />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </>
  );
};
export default CreateBlogPostDrawer;
