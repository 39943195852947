import { Col, Divider, Drawer, Row } from "antd";
import React from "react";

const InquiryDrawer = ({ selectedApplication, onClose, drawerOpen }) => {
  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );
  return (
    <Drawer width={640} placement="right" closable={false} onClose={onClose} open={drawerOpen}>
      {selectedApplication ? (
        <>
          <div className="text-xl font-bold mb-8">User Inquiry</div>
          <p className="site-description-item-profile-p font-bold">Personal Details</p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="Full Name" content={`${selectedApplication.fullName}`} />
            </Col>

            <Col span={12}>
              <DescriptionItem title="Email" content={selectedApplication.email} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Phone" content={selectedApplication.phone} />
            </Col>
          </Row>
          <Divider />
          <p className="site-description-item-profile-p font-bold">Message</p>
          <Row>
            <Col span={24}>
              <DescriptionItem title="Message" content={selectedApplication.message} />
            </Col>
          </Row>
        </>
      ) : (
        <p>No application selected</p>
      )}
    </Drawer>
  );
};

export default InquiryDrawer;
