import axios from "axios";
import { API_URL, APP_BLOG_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_BLOG_BASE_URL}`;

export async function createNewBlog(details) {
  try {
    const formData = new FormData();
    formData.append("file", details.blogImage[0]?.originFileObj);
    formData.append("title", details.title);
    formData.append("content", details.content);
    const { data } = await axios.post(`${BASE_URL}/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    return Promise.reject("Failed to create blog");
  }
}

export async function getBlogList(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    return Promise.reject("Failed to retrieve blogs");
  }
}

export async function updateBlog(details) {
  try {
    const formData = new FormData();
    console.log(details);
    formData.append("file", details.blogImage[0]?.originFileObj);
    formData.append("title", details.title);
    formData.append("content", details.content);
    formData.append("blogId", details.blog_id);
    const { data } = await axios.patch(`${BASE_URL}/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    return Promise.reject("Failed to update");
  }
}

export async function deleteBlog(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateBlogActiveStatus(blogId, active) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/active`,
      {
        blogId,
        active,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (data?.success) {
      return Promise.resolve(data);
    } else {
      return Promise.reject("UPDATE_FAILED");
    }
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateBlogHighlightingStatus(blogId, highlighting) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/highlight`,
      {
        blogId,
        highlighting,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (data?.success) {
      return Promise.resolve(data);
    } else {
      return Promise.reject("UPDATE_FAILED");
    }
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}
