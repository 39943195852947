import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Drawer, Form, Image, Input, Row, Select, Space, Upload } from "antd";
import CustomSelectButton from "./CustomSelectButton";
import toast from "react-hot-toast";
import { PlusOutlined } from "@ant-design/icons";
import { addJobCategory, addJobLocation, addJobType, deleteJobCategory, deleteJobLocation, deleteJobType, getJobCategories, getJobLocations, getJobTypes } from "../../helpers/JobApi";
import { getBase64, validateExperienceRange, validateSalaryRange } from "../../utils/utils";
import MultipleAddList from "./MultipleAddList";
import countryItems from "../../utils/countryWithFlags.json";
import { API_URL, APP_JOB_LOGO_URL } from "../../helpers/AppApiUrls";
import QuillEditor from "./QuillEditor";

const CreateJobPostDrawer = ({ open, setOpen, onFinish, defaultJobDetail }) => {
  const [form] = Form.useForm();
  const [jobTypeItems, setJobTypeItems] = useState([]);
  const [jobCategoryItems, setJobCategoryItems] = useState([]);
  const [jobLocationItems, setJobLocationItems] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState(null);

  const onClose = () => {
    form.setFieldsValue({
      categoryId: null,
      locationId: null,
      jobTypeId: null,
      salaryRange: null,
      expRange: null,
      title: null,
      companyName: null,
      gender: null,
      logo: [],
      overview: null,
      description: null,
      extra: null,
      skill: [],
      benefit: [],
      responsibility: [],
    });
    setOpen(false);
  };

  const fetchJobTypesFunc = async () => {
    let toastBox = toast;
    try {
      const getJobTypesResult = await getJobTypes();
      setJobTypeItems(getJobTypesResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  useEffect(() => {
    if (defaultJobDetail)
      form.setFieldsValue({
        categoryId: defaultJobDetail?.job_category_id,
        locationId: defaultJobDetail?.job_location_id,
        jobTypeId: defaultJobDetail?.job_type_id,
        salaryRange: defaultJobDetail?.salary_range,
        expRange: defaultJobDetail?.experience_range,
        title: defaultJobDetail?.job_name,
        companyName: defaultJobDetail?.company_name,
        gender: defaultJobDetail?.gender,
        logo: defaultJobDetail?.company_logo_name
          ? [
              {
                uid: defaultJobDetail?.company_logo_name,
                name: defaultJobDetail?.company_logo_name,
                status: "done",
                url: `${API_URL}${APP_JOB_LOGO_URL}/${defaultJobDetail?.company_logo_name}`,
              },
            ]
          : [],
        overview: defaultJobDetail?.overview,
        description: defaultJobDetail?.description,
        extra: defaultJobDetail?.extra,
        skill: defaultJobDetail?.skills?.split(",,"),
        benefit: defaultJobDetail?.benefits?.split(",,"),
        responsibility: defaultJobDetail?.responsibilities?.split(",,"),
        countryId: defaultJobDetail?.countryId,
        nationalityId: defaultJobDetail?.nationalityId?.split(",")?.map((element) => Number(element)),
      });
    else
      form.setFieldsValue({
        gender: "both",
      });
    // form.setFieldsValue({
    //   categoryId: 1,
    //   locationId: 789,
    //   jobTypeId: 11,
    //   salaryRange: "2500-3000",
    //   expRange: "2-3",
    //   title: "defaultJobDetail?.job_name",
    //   companyName: "defaultJobDetail?.company_name",
    //   gender: "both",
    //   logo: [],
    //   overview: "defaultJobDetail?.overview",
    //   description: "defaultJobDetail?.description",
    //   extra: "defaultJobDetail?.extra",
    //   skill: ["test", "test2"],
    //   benefit: ["test", "test2"],
    //   responsibility: ["test", "test2"],
    //   countryId: 1,
    //   nationalityId: [1],
    // });
  }, [defaultJobDetail]);

  const fetchJobCategoriesFunc = async () => {
    let toastBox = toast;
    try {
      const getJobCategoriesResult = await getJobCategories();
      setJobCategoryItems(getJobCategoriesResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  const fetchJobLocationsFunc = async () => {
    let toastBox = toast;
    try {
      const getJobLocationsResult = await getJobLocations();
      setJobLocationItems(getJobLocationsResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  useEffect(() => {
    fetchJobTypesFunc();
    fetchJobCategoriesFunc();
    fetchJobLocationsFunc();
  }, []);

  const handleSubmit = () => {
    form.submit();
  };

  // job type

  const handleJobTypeAddOption = async (name) => {
    let toastBox = toast;
    if (!jobTypeItems.includes(name)) {
      try {
        const addJobTypeResult = await addJobType({ jobTypeName: name });
        console.log(addJobTypeResult);
        setJobTypeItems([...jobTypeItems, addJobTypeResult]);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  const handleJobTypeRemoveOption = async (value) => {
    let toastBox = toast;
    if (jobTypeItems.find((element) => element.id === value)) {
      try {
        const addJobTypeResult = await deleteJobType({ jobTypeId: value });
        console.log(addJobTypeResult);
        setJobTypeItems(jobTypeItems.filter((element) => element?.id !== value));
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  //job category

  const handleJobCategoryAddOption = async (name) => {
    let toastBox = toast;
    if (!jobCategoryItems.includes(name)) {
      try {
        const addJobCategoryResult = await addJobCategory({ jobCategoryName: name });
        setJobCategoryItems([...jobCategoryItems, addJobCategoryResult]);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  const handleJobCategoryRemoveOption = async (value) => {
    let toastBox = toast;
    if (jobCategoryItems.find((element) => element.id === value)) {
      try {
        await deleteJobCategory({ jobCategoryId: value });
        setJobCategoryItems(jobCategoryItems.filter((element) => element?.id !== value));
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  //job location

  const handleJobLocationAddOption = async (name) => {
    let toastBox = toast;
    if (!jobLocationItems.includes(name)) {
      try {
        const addJobLocationResult = await addJobLocation({ jobLocationName: name });
        setJobLocationItems([...jobLocationItems, addJobLocationResult]);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  const handleJobLocationRemoveOption = async (value) => {
    let toastBox = toast;
    if (jobLocationItems.find((element) => element.id === value)) {
      try {
        await deleteJobLocation({ jobLocationId: value });
        setJobLocationItems(jobLocationItems.filter((element) => element?.id !== value));
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e?.fileList?.find((element) => element.size / 1024 / 1024 < 5)) {
      return [e?.fileList[0]];
    } else return null;
  };

  const validateRequired = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  });

  const beforeUpload = () => {
    return false;
  };

  const handleFileRemove = async (file) => {
    setFile(null);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  return (
    <>
      <Drawer
        title="Create a new job post"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
            overflowX: "visible",
          },
        }}
        placement="right"
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        {open && (
          <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={"optional"}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="logo"
                  label="Company Logo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Upload maxCount={1} beforeUpload={beforeUpload} onPreview={handlePreview} onRemove={handleFileRemove} accept=".jpeg,.jpg,.png,.svg" listType="picture-card">
                    {file ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job title",
                    },
                  ]}
                >
                  <Input placeholder="Please enter job title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="companyName"
                  label="Company name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter company name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="countryId"
                  label="Job Country"
                  rules={[
                    {
                      required: true,
                      message: "Please select a country",
                    },
                  ]}
                >
                  <CustomSelectButton items={countryItems} placeholder={"Select a country"} type={"permanent"} handleAddOption={(val) => handleJobLocationAddOption(val)} handleRemoveOption={(val) => handleJobLocationRemoveOption(val)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="locationId"
                  label="Job Location"
                  rules={[
                    {
                      required: true,
                      message: "Please select job location",
                    },
                  ]}
                >
                  <CustomSelectButton items={jobLocationItems} placeholder={"Select Job Location"} handleAddOption={(val) => handleJobLocationAddOption(val)} handleRemoveOption={(val) => handleJobLocationRemoveOption(val)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="nationalityId"
                  label="Nationality"
                  rules={[
                    {
                      required: true,
                      message: "Please select nationalities",
                    },
                  ]}
                >
                  <CustomSelectButton items={countryItems} placeholder={"Select nationalities"} type={"nationality"} handleAddOption={(val) => handleJobLocationAddOption(val)} handleRemoveOption={(val) => handleJobLocationRemoveOption(val)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="jobTypeId"
                  label="Job Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select job type",
                    },
                  ]}
                >
                  <CustomSelectButton items={jobTypeItems} placeholder={"Select Job Type"} handleAddOption={(val) => handleJobTypeAddOption(val)} handleRemoveOption={(val) => handleJobTypeRemoveOption(val)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="salaryRange"
                  label="Salary Range"
                  rules={[
                    {
                      required: true,
                      validator: validateSalaryRange,
                    },
                  ]}
                >
                  <Input placeholder="15000-25000" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="categoryId"
                  label="Job Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select job category",
                    },
                  ]}
                >
                  <CustomSelectButton items={jobCategoryItems} placeholder={"Select Job Category"} handleAddOption={(val) => handleJobCategoryAddOption(val)} handleRemoveOption={(val) => handleJobCategoryRemoveOption(val)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="expRange"
                  label="Experience Range"
                  rules={[
                    {
                      required: true,
                      validator: validateExperienceRange,
                    },
                  ]}
                >
                  <Input placeholder="2-3 (in years)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    // style={{
                    //   width: 120,
                    // }}
                    options={[
                      {
                        value: "male",
                        label: "Male",
                      },
                      {
                        value: "female",
                        label: "Female",
                      },
                      {
                        value: "both",
                        label: "Both",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none",
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="overview"
                  label="Overview"
                  rules={[
                    {
                      required: true,
                      message: "Please write an overview",
                    },
                  ]}
                >
                  <QuillEditor />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please write a description",
                    },
                  ]}
                >
                  <QuillEditor />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="extra"
                  label="Extra details (displayed at end)"
                  rules={[
                    {
                      required: false,
                      message: "Please write a description",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="Please write extra details" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="skill"
                  label="Skills"
                  rules={[
                    {
                      required: true,
                      message: "At least one skill required",
                    },
                  ]}
                >
                  <Form.List name="skill">{(fields, { add, remove }) => <MultipleAddList fields={fields} add={add} remove={remove} type={"skill"} />}</Form.List>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="benefit"
                  label="Benefits"
                  rules={[
                    {
                      required: true,
                      message: "At least one benefit required",
                    },
                  ]}
                >
                  <Form.List name="benefit">{(fields, { add, remove }) => <MultipleAddList fields={fields} add={add} remove={remove} type={"benefit"} />}</Form.List>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="responsibility"
                  label="Responsibilities"
                  rules={[
                    {
                      required: true,
                      message: "At least one responsibility required",
                    },
                  ]}
                >
                  <Form.List name="responsibility">{(fields, { add, remove }) => <MultipleAddList fields={fields} add={add} remove={remove} type={"responsibility"} />}</Form.List>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </>
  );
};
export default CreateJobPostDrawer;
