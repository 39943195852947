import axios from "axios";
import { API_URL, APP_JOB_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_JOB_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/user";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

// export const axiosPrivate = axios.create({
//   baseURL: `${BASE_URL}/refresh`,
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
// });

export async function getJobList(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getDashboardData(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/dashboard`, {
      params: query,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject("NO_USER_LOGIN");
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function createNewJob(details) {
  try {
    const formData = new FormData();
    formData.append("file", details.logo[0]?.originFileObj);
    formData.append("categoryId", details.categoryId);
    formData.append("locationId", details.locationId);
    formData.append("jobTypeId", details.jobTypeId);
    formData.append("salaryRange", details.salaryRange);
    formData.append("expRange", details.expRange);
    formData.append("title", details.title);
    formData.append("companyName", details.companyName);
    formData.append("gender", details.gender);
    formData.append("overview", details.overview);
    formData.append("description", details.description);
    if (details.extra) formData.append("extra", details.extra);
    formData.append("skill", JSON.stringify(details.skill));
    formData.append("benefit", JSON.stringify(details.benefit));
    formData.append("responsibility", JSON.stringify(details.responsibility));
    formData.append("countryId", details.countryId);
    formData.append("nationalityId", details.nationalityId);
    const { data } = await axios.post(`${BASE_URL}/create`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function deleteJob(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateJobPriority(details) {
  try {
    const { data } = await axios.patch(`${BASE_URL}/priority/`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateApplicationView(details) {
  try {
    const { data } = await axios.patch(`${BASE_URL}/applications/viewed`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateJob(details) {
  try {
    const formData = new FormData();
    console.log(details);
    formData.append("jobId", details.jobId);
    formData.append("file", details.logo[0]?.originFileObj);
    formData.append("categoryId", details.categoryId);
    formData.append("locationId", details.locationId);
    formData.append("jobTypeId", details.jobTypeId);
    formData.append("salaryRange", details.salaryRange);
    formData.append("expRange", details.expRange);
    formData.append("title", details.title);
    formData.append("companyName", details.companyName);
    formData.append("gender", details.gender);
    formData.append("overview", details.overview);
    formData.append("description", details.description);
    formData.append("extra", details.extra);
    formData.append("skill", JSON.stringify(details.skill));
    formData.append("benefit", JSON.stringify(details.benefit));
    formData.append("responsibility", JSON.stringify(details.responsibility));
    formData.append("countryId", details.countryId);
    formData.append("nationalityId", details.nationalityId);
    const { data } = await axios.patch(`${BASE_URL}/update`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

// job types

export async function addJobType(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/job-type/create`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function deleteJobType(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/job-type/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getJobTypes() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-type`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

// job categories

export async function getJobCategories() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-category`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function addJobCategory(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/job-category/create`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function deleteJobCategory(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/job-category/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

// job locations

export async function getJobLocations() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-location`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function addJobLocation(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/job-location/create`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function deleteJobLocation(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/job-location/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

// job applications

export async function getJobApplications(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/applications`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteJobApplications(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/applications/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteOnRemoveFile(fileName) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/applications/upload`, {
      data: { filename: fileName },
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject("NO_USER_LOGIN");
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateHighlightingStatus(jobId, highlighting) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/highlight`,
      {
        jobId,
        highlighting,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (data?.success) {
      return Promise.resolve(data);
    } else {
      return Promise.reject("UPDATE_FAILED");
    }
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateJobActiveStatus(jobId, active) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/active`,
      {
        jobId,
        active,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (data?.success) {
      return Promise.resolve(data);
    } else {
      return Promise.reject("UPDATE_FAILED");
    }
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}
