import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, List, Pagination, Row, Select, Table } from "antd";
import toast from "react-hot-toast";
import { getJobApplications, deleteJobApplications, updateApplicationView } from "../../helpers/JobApi";
import "./JobApplications.css";
import { confirmModalRef } from "../globalComponents/ConfirmModal";
import { API_URL, APP_JOB_RESUME_URL } from "../../helpers/AppApiUrls";
import { convertUTCtoIST } from "../../utils/utils";
import { DownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const DescriptionItem = ({ title, content }) => {
  if (title === "Languages") {
    return (
      <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content.map((lang) => (
          <span className="flex flex-row items-center gap-1">
            <span className="anticon anticon-check">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
              </svg>
            </span>
            {lang}
          </span>
        ))}
      </div>
    );
  } else if (title === "resume") {
    return <div className="site-description-item-profile-wrapper">{content}</div>;
  } else {
    return (
      <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title} :</p>
        {content}
      </div>
    );
  }
};

const languageMapping = {
  En_read: "English Read",
  En_write: "English Write",
  En_speak: "English Speak",

  Eng_read: "English Read",
  Eng_write: "English Write",
  Eng_speak: "English Speak",

  Hn_read: "Hindi Read",
  Hn_write: "Hindi Write",
  Hn_speak: "Hindi Speak",

  Hin_read: "Hindi Read",
  Hin_write: "Hindi Write",
  Hin_speak: "Hindi Speak",

  Kan_read: "Kannada Read",
  Kan_write: "Kannada Write",
  Kan_speak: "Kannada Speak",

  Tel_read: "Telugu Read",
  Tel_write: "Telugu Write",
  Tel_speak: "Telugu Speak",
};

const transformLanguageDataForTable = (languages) => {
  // Initialize languageData with all languages set to ❌
  const languageData = {};
  for (const key in languageMapping) {
    const languageName = languageMapping[key].split(" ")[0];
    if (!languageData[languageName]) {
      languageData[languageName] = { language: languageName, read: "❌", write: "❌", speak: "❌" };
    }
  }

  // Update languageData based on the input languages
  languages.split(",").forEach((code) => {
    const [lang, skill] = code.split("_");
    const languageName = languageMapping[`${lang}_read`]?.split(" ")[0];
    if (skill === "read") {
      languageData[languageName].read = "✔️";
    } else if (skill === "write") {
      languageData[languageName].write = "✔️";
    } else if (skill === "speak") {
      languageData[languageName].speak = "✔️";
    }
  });

  console.log(languageData);
  return Object.values(languageData);
};

const DescriptionTable = ({ languages }) => {
  const dataSource = transformLanguageDataForTable(languages);

  const columns = [
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Speak",
      dataIndex: "speak",
      key: "speak",
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
    },
    {
      title: "Write",
      dataIndex: "write",
      key: "write",
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} rowKey="language" />
    </div>
  );
};

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const transformLanguageData = (languages) => {
  const language = languages.split(",").map((code) => languageMapping[code] || code);
  return language;
};

const JobApplications = () => {
  const [jobApplications, setJobApplications] = useState([]);
  const [filteredJobApplications, setFilteredJobApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentJobs, setCurrentJobs] = useState([]);
  // const pageSize = 9;
  const handlePageChange = (e, size) => {
    setPageSize(size);
    setPage(e);
  };

  useEffect(() => {
    const indexOfLastJob = page * pageSize;
    const indexOfFirstJob = indexOfLastJob - pageSize;
    const filteredData = jobApplications
      ?.filter((element) => {
        if (selectedItems.length !== 0) return selectedItems.includes(element.job_id);
        else return true;
      })
      .map((element, index) => ({ ...element, index: index + 1 }));
    setFilteredJobApplications(filteredData);
    setCurrentJobs(filteredData?.slice(indexOfFirstJob, indexOfLastJob));
  }, [page, jobApplications, pageSize, selectedItems]);

  useEffect(() => {
    const jobIdRaw = location?.search?.split("job=")[1];
    if (jobIdRaw) {
      const filteredJob = jobApplications?.find((element) => element.job_id === Number(jobIdRaw));
      setSelectedItems([filteredJob?.job_id]);
    }
  }, [jobApplications, location]);

  const getJobApplicationsFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const jobApplicationsResult = await getJobApplications();
      setJobApplications(jobApplicationsResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobApplicationsFunc();
  }, []);

  const showDrawer = async (item) => {
    let toastBox = toast;
    if (!item?.viewed) {
      try {
        await updateApplicationView({ applicationId: item.id });
        await getJobApplicationsFunc();
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    }

    setSelectedApplication(item);
    setDrawerOpen(true);
  };

  const onClose = () => {
    setDrawerOpen(false);
    setSelectedApplication(null); // Clear the selected application when closing the drawer
  };
  const handleRemoveApplicationConfirmation = async (applicationId) => {
    let toastBox = toast;
    try {
      await deleteJobApplications({ applicationId });
      toast.success(`Application removed!`, {
        id: toastBox,
      });
      await getJobApplicationsFunc();
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };
  const handleRemoveApplication = async (applicationId) => {
    confirmModalRef.current.open("This job application will be deleted permanently!", "Are you sure?", async () => {
      handleRemoveApplicationConfirmation(applicationId);
    });
  };

  return (
    <div>
      <div className="border-b-2 w-full py-4 flex items-center justify-between">
        <span className="text-2xl font-medium capitalize">Job Applications</span>
        <Select
          mode="multiple"
          placeholder="Default Filter Selected All"
          value={selectedItems}
          onChange={(e) => {
            setSelectedItems(e);
          }}
          style={{
            width: "15vw",
          }}
          options={jobApplications
            .reduce((unique, item) => {
              if (!unique.some((obj) => obj.job_id === item.job_id)) {
                unique.push(item);
              }
              return unique;
            }, [])
            .map((item) => {
              console.log(item);
              return {
                value: item.job_id,
                label: item.job_name,
              };
            })}
        />
      </div>
      <List
        dataSource={currentJobs}
        loading={loading}
        // pagination={{
        //   onChange: (page) => {
        //     console.log(page);
        //   },
        //   pageSize: 8,
        // }}
        renderItem={(item) => {
          const isViewed = !item?.viewed || false;
          return (
            <List.Item
              className={`hover:bg-slate-100 transition duration-300 ease-in-out ${isViewed && `bg-yellow-100 border`}`}
              key={item.id}
              actions={[
                <button type="button" onClick={() => showDrawer(item)} key={`a-${item.id}`}>
                  View Profile
                </button>,
                <button type="button" key="list-loadmore-more" className="text-red-600" onClick={() => handleRemoveApplication(item?.id)}>
                  Delete
                </button>,
              ]}
            >
              <List.Item.Meta
                className="px-2 hover:cursor-pointer "
                avatar={<span>{item.index}.</span>}
                title={
                  <span className="text-base font-semibold">
                    {item.company_name} - {item.job_name}
                    <span className="ml-1 text-xs text-green-500">{isViewed && `NEW`}</span>
                  </span>
                }
                onClick={() => showDrawer(item)}
                key={`a-${item.id}`}
                description={
                  <div>
                    <span className="border-r pr-2">
                      Name: {item.first_name} {item.last_name}
                    </span>
                    <span className="border-r px-2">E-Mail: {item.email}</span>
                    <span className="border-r px-2">Nationality: {item.nationality}</span>
                    <span className="border-r px-2">Gender: {item.gender}</span>
                    <span className="px-2">Applied on: {convertUTCtoIST(item.created_at)}</span>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <Pagination size="default" defaultCurrent={1} total={filteredJobApplications?.length} onChange={handlePageChange} showSizeChanger showQuickJumper showLessItems className="text-end mt-2" />
      <Drawer width={640} placement="right" closable={false} onClose={onClose} open={drawerOpen}>
        {selectedApplication ? (
          <>
            <div className="text-xl font-bold mb-8">User Profile</div>
            <p className="site-description-item-profile-p font-bold">Personal Details</p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Full Name" content={`${selectedApplication.first_name} ${selectedApplication.last_name}`} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Nationality" content={selectedApplication.nationality} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Gender" content={selectedApplication.gender} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Date of Birth" content={formatDate(selectedApplication.dob)} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Marital Status" content={selectedApplication.marital_status} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Current Address" content={selectedApplication.current_address === "undefined" ? "Not provided" : selectedApplication.current_address} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Permanent Address" content={selectedApplication.permanent_address === "undefined" ? "Not provided" : selectedApplication.permanent_address} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Visa Status" content={selectedApplication.visa_status} />
              </Col>
              <Col span={12}>
                <div className="site-description-item-profile-wrapper flex flex-row items-center">
                  <p className="site-description-item-profile-p-label">Passport:</p>
                  <div className={`w-fit text-xs font-medium me-2 px-2.5 py-0.5 rounded ${selectedApplication.passport === 1 ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300" : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"}`}>
                    {selectedApplication.passport ? "True" : "False"}
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p font-bold">Job Details</p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Job Name" content={selectedApplication.job_name} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Company" content={selectedApplication.company_name} />
              </Col>
              <Col span={24}>
                <DescriptionTable languages={selectedApplication.languages} />
              </Col>
            </Row>

            <Divider />
            <p className="site-description-item-profile-p font-bold">Contacts</p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Phone Number" content={selectedApplication.phone_number} />
              </Col>
              <Col span={24}>
                <Col span={12}>
                  <DescriptionItem title="Email" content={selectedApplication.email} />
                </Col>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title={"resume"}
                  content={
                    <a href={`${API_URL}${APP_JOB_RESUME_URL}/${selectedApplication.resume_name}`} download target="_blank" rel="noopener noreferrer">
                      <Button type="primary" icon={<DownloadOutlined />}>
                        Download Resume
                      </Button>
                    </a>
                  }
                />
              </Col>
            </Row>
          </>
        ) : (
          <p>No application selected</p>
        )}
      </Drawer>
    </div>
  );
};

export default JobApplications;
