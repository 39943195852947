import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Skeleton } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { deleteAdmin, getAdmin } from "../../helpers/UserApi";
import toast from "react-hot-toast";
import AddAdminModal from "./components/AddAdminModal";
import { confirmModalRef } from "../globalComponents/ConfirmModal";
// Remove imports for CreateAdminDrawer and ConfirmModal

const AdminSettings = () => {
  const [open, setOpen] = useState(false); // Can be removed if not used
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const getAdminFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const adminListResult = await getAdmin();
      setAdminList(adminListResult);
      console.log(adminListResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminFunc();
  }, []);

  const handleAddAdmin = () => {
    setOpen(true);
  };

  const onCLose = () => {
    setOpen(false);
    setSelectedAdmin(null);
  };

  const onAdminAdded = () => {
    getAdminFunc();
  };

  const handleEditModal = (e) => {
    setSelectedAdmin(e);
    setOpen(true);
  };

  const handleDeleteModal = (e) => {
    setSelectedAdmin(e);
    confirmModalRef.current.open("This admin will be deleted", "Are you sure?", async () => {
      let toastBox = toast;
      try {
        await deleteAdmin(e?.username);
        toast.success("Admin deleted!", {
          id: toastBox,
        });
        getAdminFunc();
      } catch (error) {
        console.log(error);
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    });
  };

  return (
    <div className="">
      <AddAdminModal open={open} onAdminAdded={onAdminAdded} onClose={onCLose} adminDetail={selectedAdmin} />
      <div className="flex justify-end">
        <Button className="flex items-center px-4 py-5 text-lg rounded-lg font-semibold border-green-400" onClick={handleAddAdmin}>
          Add Admin
          <UserAddOutlined className="text-green-600" size={30} />
        </Button>
      </div>
      <div className="">
        <div className="border-b-2 w-full py-4">
          <span className="text-2xl font-medium capitalize">Admin Settings</span>
        </div>
        <List
          className="demo-loadmore-list"
          // loading={initLoading}
          itemLayout="horizontal"
          dataSource={adminList}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a key="list-loadmore-edit" className="text-base" onClick={() => handleEditModal(item)}>
                  Edit
                </a>,
                <a key="list-loadmore-more" className="text-base" onClick={() => handleDeleteModal(item)}>
                  Delete
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  // avatar={<Avatar src={item.picture.large} />}
                  title={<span className="font-semibold text-base">{item.username}</span>}
                  // description={<span className=' '></span>}
                />
                {/* <div className="">
                  <div className="text-green-600 text-base">{item.flag}</div>
                </div> */}
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default AdminSettings;
