import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import { confirmModalRef } from "./ConfirmModal";

const CustomSelectButton = ({ items, value, onChange, handleRemoveOption, handleAddOption, placeholder, type }) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    handleAddOption(name);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleDeleteButton = (option) => {
    confirmModalRef.current.open("Any job post having this item will be deleted!", "Are you sure?", async () => {
      handleRemoveOption(option.value);
      if (value === option.value) onChange(null);
    });
  };

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <>
      <Select
        showSearch
        mode={type === "nationality" && "multiple"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
        dropdownRender={(menu) => (
          <>
            {menu}
            {type !== "permanent" && type !== "nationality" && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <div style={{ padding: "0 8px 4px" }} className="flex items-center justify-between">
                  <Input placeholder="Job type label" ref={inputRef} value={name} onChange={onNameChange} onKeyDown={(e) => e.stopPropagation()} />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Add
                  </Button>
                </div>
              </>
            )}
          </>
        )}
        optionRender={(option) => (
          <>
            <div className="flex items-center justify-between">
              <span>{option.label}</span>
              {type !== "permanent" && type !== "nationality" && (
                <button type="button" onClick={() => handleDeleteButton(option)}>
                  <MinusCircleOutlined className="text-red-600" />
                </button>
              )}
            </div>
          </>
        )}
        options={items.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
      />
    </>
  );
};
export default CustomSelectButton;
