import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
const MultipleAddList = ({ fields, add, remove, type }) => (
  <>
    {fields.map(({ key, name, ...restField }) => (
      <div key={key} align="start" className="w-full flex gap-6 items-start">
        <Form.Item
          {...restField}
          name={name}
          rules={[
            {
              required: true,
              message: `Please define the ${type}`,
            },
          ]}
          className="w-full"
        >
          <Input.TextArea className="w-full" rows={1} placeholder={`${type} details`} />
        </Form.Item>
        <MinusCircleOutlined onClick={() => remove(name)} />
      </div>
    ))}
    <Form.Item>
      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
        Add field
      </Button>
    </Form.Item>
  </>
);
export default MultipleAddList;
