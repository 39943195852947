import React, { useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";
import toast from "react-hot-toast";
import { registerAdmin, updateAdmin } from "../../../helpers/UserApi";

const AddAdminModal = ({ open, onClose, onAdminAdded, adminDetail }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        let toastBox = toast;
        try {
          if (adminDetail) await updateAdmin(values);
          else await registerAdmin(values);
          if (adminDetail)
            toast.success("Admin updated successfully", {
              id: toastBox,
            });
          else
            toast.success("Admin registered successfully", {
              id: toastBox,
            });
          onAdminAdded(); // Notify parent to refresh the admin list
          onClose();
        } catch (error) {
          toast.error(`${error}`, {
            id: toastBox,
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (adminDetail)
      form.setFieldsValue({
        username: adminDetail?.username,
      });
  }, [adminDetail]);

  return (
    <Modal
      title="Add Admin"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="username" label="Username" rules={[{ required: true, message: "Please input the username!" }]}>
          <Input />
        </Form.Item>
        {adminDetail ? (
          <>
            <Form.Item name="password" label="New Password" rules={[{ required: true, message: "Please input the new password!" }]}>
              <Input.Password />
            </Form.Item>
          </>
        ) : (
          <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please input the password!" }]}>
            <Input.Password />
          </Form.Item>
        )}
        {/* <Form.Item name="flags" label="Flag" rules={[{ required: true, message: "Please input the flag!" }]}>
          <Input />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddAdminModal;
