import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, List, Pagination, Space, Switch } from "antd";
import toast from "react-hot-toast";
import { confirmModalRef } from "../globalComponents/ConfirmModal";
import { convertUTCtoIST } from "../../utils/utils";
import CreateFaqPostDrawer from "./components/CreateFaqPostDrawer";
import { createNewFAQ, deleteFAQ, getFAQList, updateFAQ, updateFAQActiveStatus } from "../../helpers/FaqApi";

const FaqSettings = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [defaultFaqDetail, setDefaultFaqDetail] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentFAQs, setCurrentFAQs] = useState([]);
  // const pageSize = 9;
  const handlePageChange = (e, size) => {
    setPageSize(size);
    setPage(e);
  };

  useEffect(() => {
    const indexOfLastJob = page * pageSize;
    const indexOfFirstJob = indexOfLastJob - pageSize;
    setCurrentFAQs(faqList?.slice(indexOfFirstJob, indexOfLastJob));
  }, [page, faqList, pageSize]);

  const handleActiveChange = async (faqId, checked) => {
    const active = checked ? "true" : "false";
    const prevJobs = [...faqList];
    setFaqList((prevJobs) => prevJobs.map((faq) => (faq.id === faqId ? { ...faq, active } : faq)));

    try {
      await updateFAQActiveStatus(faqId, active);
    } catch (error) {
      setFaqList(prevJobs);
    }
  };

  const handleRemoveFaq = async (faqId) => {
    confirmModalRef.current.open("This FAQ will be deleted permanently!", "Are you sure?", async () => {
      let toastBox = toast;
      try {
        await deleteFAQ({ faqId: faqId });
        toast.success("FAQ deleted!", {
          id: toastBox,
        });
        getFaqListFunc();
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    });
  };

  const getFaqListFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const faqListResult = await getFAQList();
      console.log(faqListResult);
      setFaqList(faqListResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    let toastBox = toast;
    if (defaultFaqDetail) {
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await updateFAQ({ ...values, faq_id: defaultFaqDetail?.id });
        console.log(postJobApplicationResult);
        toast.success(`Job updated!`, {
          id: toastBox,
        });
        await getFaqListFunc();
        setOpen(!open);
        setDefaultFaqDetail(null);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    } else
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await createNewFAQ(values);
        console.log(postJobApplicationResult);
        toast.success(`Application submitted!`, {
          id: toastBox,
        });
        await getFaqListFunc();
        setOpen(!open);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
  };

  const handleEditFaq = (faq_Id) => {
    const selectedFaqDetail = faqList.find((element) => element.id === faq_Id);
    setDefaultFaqDetail(selectedFaqDetail);
    setOpen(true);
  };

  useEffect(() => {
    getFaqListFunc();
  }, []);

  return (
    <div>
      <CreateFaqPostDrawer
        open={open}
        defaultJobDetail={defaultFaqDetail}
        setOpen={(bool) => {
          if (!bool) setDefaultFaqDetail(null);
          setOpen(bool);
        }}
        onFinish={(val) => onFinish(val)}
      />
      <div className="w-full">
        <div className="w-full text-end">
          <Button className="capitalize" size="large" type="primary" onClick={() => setOpen(!open)}>
            <div className="gap-1 flex items-center">
              <PlusOutlined />
              Create new FAQ
            </div>
          </Button>
        </div>
        <div>
          <div className="border-b-2 w-full py-4 flex items-center justify-between">
            <span className="text-2xl font-medium capitalize">Faq lists</span>
          </div>
          <List
            itemLayout="horizontal"
            loading={loading}
            dataSource={currentFAQs}
            // pagination={{
            //   onChange: (page) => {
            //     console.log(page);
            //   },
            //   pageSize: 8,
            // }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <div key={item.id}>
                    <Space direction="horizontal">
                      <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} checked={item.active === "true"} onChange={(checked) => handleActiveChange(item.id, checked)} />
                    </Space>
                  </div>,
                  <button type="button" key="list-loadmore-edit" className="text-amber-500" onClick={() => handleEditFaq(item.id)}>
                    edit
                  </button>,
                  <button type="button" key="list-loadmore-more" className="text-red-600" onClick={() => handleRemoveFaq(item.id)}>
                    remove
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div className="flex items-center gap-4">
                      <Space direction="vertical">
                        <span className="text-xl font-medium">{item.title}</span>
                        <span className="">Created on: {convertUTCtoIST(item.created_at)}</span>
                      </Space>
                    </div>
                  }
                  description={<div></div>}
                />
              </List.Item>
            )}
          />
          <Pagination size="default" defaultCurrent={1} total={faqList?.length} onChange={handlePageChange} showSizeChanger showQuickJumper showLessItems className="text-end mt-2" />
        </div>
      </div>
    </div>
  );
};

export default FaqSettings;
