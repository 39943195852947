import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { activeUser } from "../../helpers/UserApi";
import UseAuth from "../../hooks/UseAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth, setAuth } = UseAuth();
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  useEffect(() => {
    async function checkUserActive() {
      try {
        const activeUserPromise = await activeUser();
        setAuth({ ...auth, userActive: activeUserPromise?.msg, userType: ["master"] });
      } catch (error) {
        const status = error?.response?.status;
      } finally {
        setIsChecked(true);
      }
    }
    checkUserActive();
    // eslint-disable-next-line
  }, []);

  return <>{isChecked ? Array.isArray(auth?.userType) && auth?.userType?.find((role) => allowedRoles?.includes(role)) ? <Outlet /> : auth?.userActive ? <Navigate to="/unauthorized" state={{ from: location }} replace /> : <Navigate to="/login" state={{ from: location }} replace /> : null}</>;
};

export default RequireAuth;
