import axios from "axios";
import { API_URL, APP_FAQ_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_FAQ_BASE_URL}`;

export async function createNewFAQ(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    else return Promise.reject("Failed to create FAQ");
  }
}

export async function getFAQList(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    else return Promise.reject("Failed to retrieve FAQs");
  }
}

export async function updateFAQ(details) {
  try {
    const formData = new FormData();
    console.log(details);
    formData.append("title", details.title);
    formData.append("content", details.content);
    formData.append("faqId", details.faq_id);
    const { data } = await axios.patch(`${BASE_URL}/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    else return Promise.reject("Failed to update FAQ");
  }
}

export async function deleteFAQ(details) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    else return Promise.reject("Failed to delete FAQ");
  }
}

export async function updateFAQActiveStatus(faqId, active) {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/active`,
      {
        faqId,
        active,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (data?.success) {
      return Promise.resolve(data);
    } else {
      return Promise.reject("UPDATE_FAILED");
    }
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    else return Promise.reject("Failed to update FAQ");
  }
}
