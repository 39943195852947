import React, { useEffect } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import logo from "../../data/logo/PNG/Dubai Job finder logo final file_Logo black.png";
import { loginAdmin } from "../../helpers/UserApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import UseAuth from "../../hooks/UseAuth";
const AdminLogin = () => {
  const { auth } = UseAuth();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    let toastBox = toast;
    try {
      const loginAdminResult = await loginAdmin(values);
      toast.success(`Logged in!`, {
        id: toastBox,
      });
      navigate("/overview");
    } catch (error) {
      console.log(error);
      toast.error(`Invalid credentials`, {
        id: toastBox,
      });
    }
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (auth?.userActive === "active") navigate("/overview");
  }, []);

  return (
    <div className="flex min-h-screen justify-center items-center w-screen sm:pl-0 pl-5 sm:pr-0 pr-5">
      <div className="shadow-2xl xs:p-24 rounded-3xl xs:w-[600px] w-screen p-4 ">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          style={
            {
              // position: 'absolute',
              // top: '50%',
              // left: '50%',
              // border: '2px solid',
              // padding: '10px'
              // width: 400,
              // border: '5px solid #44469080',
              // padding: '40px',
              // borderRadius: '35px'
            }
          }
          size="large"
        >
          <Form.Item>
            {/* <span className='font-bold flex flex-col ustify-center items-center pb-8'>Admin Login</span>
             */}
            <div className="font-bold flex flex-col justify-center items-center pb-8">
              <img src={logo} alt="logo" width={212}></img>
            </div>
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button bg-[#444690] mt-4 px-8 rounded-full">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default AdminLogin;
