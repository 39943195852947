import { List, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getInquiry } from "../../../helpers/UserApi";
import InquiryDrawer from "./InquiryDrawer";

const InquiryList = ({ inquiryList, loading }) => {
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [open, setOpen] = useState(false);

  const showDrawer = (item) => {
    setSelectedInquiry(item);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="border-b-2 py-2">
        <span className="text-2xl font-medium capitalize">Inquiry List</span>
      </div>
      <InquiryDrawer selectedApplication={selectedInquiry} drawerOpen={open} onClose={onClose} />
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={inquiryList}
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 8,
        }}
        renderItem={(item) => (
          <List.Item className="hover:bg-slate-100 transition duration-300 ease-in-out hover:cursor-pointer">
            <List.Item.Meta
              onClick={() => showDrawer(item)}
              title={<span>{item.fullName}</span>}
              description={
                <div>
                  <span className="border-r pr-2">Email: {item.email}</span>
                  <span className=" px-2">Phone: {item.phone}</span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default InquiryList;
