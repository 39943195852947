import React, { useEffect, useState } from "react";
import StatCard from "./components/StatCard";
import { CalendarOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Card, Spin } from "antd";
import { getDashboardData } from "../../helpers/JobApi";
import toast from "react-hot-toast";
import InquiryList from "./components/InquiryList";
import { getInquiry } from "../../helpers/UserApi";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);

  const getInquiryFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const jobListResult = await getInquiry();
      console.log(jobListResult);
      setInquiryList(jobListResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardDataFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const getDashboardDataResult = await getDashboardData();
      setDashboardData(getDashboardDataResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInquiryFunc();
    fetchDashboardDataFunc();
  }, []);

  return (
    <div>
      <div className="text-end">
        <Button
          type="primary"
          icon={<RedoOutlined />}
          onClick={() => {
            getInquiryFunc();
            fetchDashboardDataFunc();
          }}
        >
          Reload
        </Button>
      </div>

      {/* {DASHBOARD_DATA.map((element, index) => (
        <StatCard title={element.title} bgOuterColor={element.bgOuterColor} bgInnerColor={element.bgInnerColor} IconComponent={CalendarOutlined} count={element.count} />
      ))} */}
      <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 min-[1729px]:grid-cols-5 gap-2 p-4">
        <StatCard title={"Total Applicants"} bgOuterColor={"bg-blue-100"} bgInnerColor={"bg-blue-200"} IconComponent={CalendarOutlined} count={dashboardData?.totalApplicants} />
        <StatCard title={"Applicant Today"} bgOuterColor={"bg-green-100"} bgInnerColor={"bg-green-200"} IconComponent={CalendarOutlined} count={dashboardData?.totalToday} />
        <StatCard title={"Applicant This Week"} bgOuterColor={"bg-yellow-100"} bgInnerColor={"bg-yellow-200"} IconComponent={CalendarOutlined} count={dashboardData?.totalThisWeek} />
        <StatCard title={"Total Active Job Post"} bgOuterColor={"bg-teal-100"} bgInnerColor={"bg-teal-200"} IconComponent={CalendarOutlined} count={dashboardData?.totalActiveJobs} />
        <StatCard title={"Total Inactive Job Post"} bgOuterColor={"bg-red-100"} bgInnerColor={"bg-red-200"} IconComponent={CalendarOutlined} count={dashboardData?.totalInactiveJobs} />
      </div>
      <Spin spinning={loading} fullscreen />
      <div className="mt-4">
        <InquiryList inquiryList={inquiryList} loading={loading} />
      </div>
    </div>
  );
};

export default Dashboard;

const DASHBOARD_DATA = [
  {
    title: "Test title",
    count: 2,
    bgInnerColor: "bg-blue-200",
    bgOuterColor: "bg-blue-100",
    IconComponent: CalendarOutlined,
  },
];
