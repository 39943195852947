import React, { useEffect, useState } from "react";
import { AppstoreOutlined, TeamOutlined, MenuFoldOutlined, MenuUnfoldOutlined, AuditOutlined, ProjectOutlined, SettingOutlined, LogoutOutlined, SnippetsOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout, Menu, theme, ConfigProvider } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PageNotFound from "./pageNotFound/PageNotFound";
import JobApplications from "./jobApplications/JobApplications";
import AdminLogin from "./adminLogin/AdminLogin";
import { logoutAdmin } from "../helpers/UserApi";
import UseAuth from "../hooks/UseAuth";
import AdminSettings from "./adminSettings/AdminSettings";
import JobPost from "./jobPost/JobPost";
import ComingSoon from "./comingSoon/ComingSoon";
import logo from "../data/logo/PNG/Dubai Job finder logo final file_Logo black.png";
import Test from "./test/Test";
import EditorContextProvider from "./globalComponents/EditorContext";
import Dashboard from "./dashboard/Dashboard";
import CmsPage from "./siteSettings/CmsPage";
import BlogPosts from "./blogPosts/BlogPosts";
import FaqSettings from "./faqSettings/FaqSettings";
import { confirmModalRef } from "./globalComponents/ConfirmModal";
const { Header, Content, Footer, Sider } = Layout;

const AdminPanel = () => {
  const handleLogout = async () => {
    confirmModalRef.current.open("you want to logout?", "Are you sure?", async () => {
      try {
        await logoutAdmin();
        setAuth({});
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    });
  };

  const items = [
    {
      key: "1",
      logged: true,
      icon: <AppstoreOutlined style={{ fontSize: 20 }} />,
      location: "/dashboard",
      label: (
        <a href="#/dashboard" className="text-lg">
          Dashboard
        </a>
      ),
    },
    {
      key: "2",
      logged: true,
      icon: <ProjectOutlined style={{ fontSize: 20 }} />,
      location: "/job-posts",
      //  location: window.location.hash,
      label: (
        <a href="#/job-posts" className="text-lg">
          Job Posts
        </a>
      ),
    },
    {
      key: "3",
      logged: true,
      icon: <AuditOutlined style={{ fontSize: 20 }} />,
      location: "/job-applications",
      label: (
        <a href="#/job-applications" className="text-lg">
          Job Applications
        </a>
      ),
    },
    {
      key: "4",
      logged: true,
      icon: <TeamOutlined style={{ fontSize: 20 }} />,
      location: "/admin-settings",
      label: (
        <a href="#/admin-settings" className="text-lg">
          Admin Settings
        </a>
      ),
    },
    {
      key: "5",
      logged: true,
      icon: <SnippetsOutlined style={{ fontSize: 20 }} />,
      location: "/blog-posts",
      label: (
        <a href="#/blog-posts" className="text-lg">
          Blog Posts
        </a>
      ),
    },
    {
      key: "6",
      logged: true,
      icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
      location: "/faq-settings",
      label: (
        <a href="#/faq-settings" className="text-lg">
          FAQ settings
        </a>
      ),
    },
    {
      key: "7",
      logged: true,
      icon: <SettingOutlined style={{ fontSize: 20 }} />,
      location: "/site-settings",
      label: <span className="text-lg">Site Settings</span>,
      children: [
        {
          key: "5-1",
          label: <a href="#/site-settings/privacy-policy">Privacy Policy</a>,
        },
        {
          key: "5-2",
          label: <a href="#/site-settings/terms-conditions">Terms & Conditions</a>,
        },
        {
          key: "5-3",
          label: <a href="#/site-settings/service-agreement">Service Agreement</a>,
        },
        {
          key: "5-4",
          label: <a href="#/site-settings/about-us">About Us</a>,
        },
      ],
    },
    {
      key: "8",
      logged: true,
      icon: <LogoutOutlined style={{ fontSize: 20 }} />,
      //  location: window.location.hash,
      onClick: handleLogout,
      label: <span className="text-lg">Logout</span>,
    },
    {
      key: "9",
      logged: false,
      icon: <LogoutOutlined style={{ fontSize: 20 }} />,
      //   location: window.location.hash,
      label: (
        <a href="#/login" className="text-lg">
          Login
        </a>
      ),
    },
  ];

  const navigate = useNavigate();
  const { auth, setAuth } = UseAuth();
  const [collapsed, setCollapsed] = useState(false);

  const onClose = () => {
    setCollapsed(false);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // useEffect(() => {
  //   const hash = location.hash || "#";
  //   setNavSelectedKey([]);
  //   // eslint-disable-next-line
  // }, [location]);

  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("1");

  useEffect(() => {
    // Update the selectedKey based on the location's hash
    const newSelectedKey =
      items.find((element) => {
        return location.pathname?.includes(element.location);
      })?.key || "1";
    setSelectedKey(newSelectedKey);
  }, [location, items]); // Dependency array includes `location` to re-run the effect when it changes

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: "#ffffff",
          },
        },
      }}
    >
      <Layout hasSider>
        <Sider
          className="shadow-lg"
          breakpoint="md"
          collapsedWidth="0"
          width={360}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div className="demo-logo-vertical w-full mx-auto py-3.5 pl-4 border-b">
            <span className="text-3xl font-medium">
              <img src={logo} alt="logo" width={150}></img>
            </span>
          </div>
          <Menu
            theme="light"
            className="h-4/5"
            mode="vertical"
            selectedKeys={[selectedKey]}
            defaultSelectedKeys={items.find((element) => element.location === window.location.hash)?.key || "1"}
            items={auth?.userActive === "active" ? items.filter((element) => element.logged) : items.filter((element) => !element.logged)}
          />
        </Sider>
        <Drawer className="custom-drawer" title="Menu" placement="left" onClose={onClose} open={collapsed}>
          <Menu
            className="custom-menu h-4/5"
            mode="vertical"
            theme="light"
            defaultSelectedKeys={items.find((element) => element.location === window.location.hash)?.key || "1"}
            items={auth?.userActive === "active" ? items.filter((element) => element.logged) : items.filter((element) => !element.logged)}
          />
        </Drawer>
        <Layout className="md:ml-[360px]">
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <div className="md:hidden">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            {/* <CreateJobPostDrawer open={open} setOpen={(bool) => setOpen(bool)} /> */}
            {/* <AdminSettings open={open} setOpen={(bool) => setOpen(bool)} /> */}
            <div
              style={{
                padding: 24,
                // textAlign: "center",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/job-posts"
                  element={
                    <EditorContextProvider>
                      <JobPost />
                    </EditorContextProvider>
                  }
                />
                <Route path="/job-applications" element={<JobApplications />} />
                <Route path="/login" element={<AdminLogin />} />
                <Route path="/admin-settings" element={<AdminSettings />} />
                <Route path="/site-settings/:type" element={<CmsPage />} />
                <Route path="/blog-posts/" element={<BlogPosts />} />
                <Route path="/faq-settings/" element={<FaqSettings />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Dubai Job Finder ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AdminPanel;
