import axios from "axios";
import { API_URL, APP_CMS_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_CMS_BASE_URL}`;

export async function getCmsContent(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/${query}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateCmsContent(query, details) {
  try {
    const { data } = await axios.put(`${BASE_URL}/${query}`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}
