export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RangePattern = /^\d+-\d+$/;

// Custom validator
export const validateSalaryRange = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please enter a salary range"));
  }
  if (!RangePattern.test(value)) {
    return Promise.reject(new Error("Please enter a valid salary range in the format 15000-25000"));
  }
  // Additional check to ensure first number is less than second number
  const [min, max] = value.split("-").map(Number);
  if (min >= max) {
    return Promise.reject(new Error("The first number must be less than the second number"));
  }
  return Promise.resolve();
};

export const validateExperienceRange = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please enter a experience range"));
  }
  if (!RangePattern.test(value)) {
    return Promise.reject(new Error("Please enter a valid experience range in the format 2-3"));
  }
  // Additional check to ensure first number is less than second number
  const [min, max] = value.split("-").map(Number);
  if (min >= max) {
    return Promise.reject(new Error("The first number must be less than the second number"));
  }
  return Promise.resolve();
};

export function convertUTCtoIST(utcDateString) {
  const date = new Date(utcDateString);

  date.setMinutes(date.getMinutes());

  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric", // 2024
    month: "long", // June, July, etc.
    day: "numeric", // 2, 3, etc.
    hour: "numeric", // hour (1-12)
    minute: "2-digit", // minute (00-59)
    hour12: true, // Use 12-hour format
    timeZone: "Asia/Kolkata", // Time zone
  });

  return formattedDate;
}
