import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCmsContent, updateCmsContent } from "../../helpers/CmsApi";
import toast from "react-hot-toast";
import QuillEditor from "../globalComponents/QuillEditor";
import { Button, Spin } from "antd";

const CmsPage = () => {
  const location = useLocation();
  const [cmsContent, setCmsContent] = useState(null);
  const [originalCmsContent, setOriginalCmsContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const path = location.pathname.split("/")[2];
  const cmsContentFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const getCmsContentResult = await getCmsContent(path);
      setOriginalCmsContent(getCmsContentResult?.content);
      setCmsContent(getCmsContentResult?.content);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    cmsContentFunc();
    console.log(path);
  }, [location]);

  const onChange = (e) => {
    setCmsContent(e);
  };

  const handleSaveButton = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      await updateCmsContent(path, { content: cmsContent });
      setOriginalCmsContent(cmsContent);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetButton = () => {
    setCmsContent(originalCmsContent);
  };

  return (
    <div>
      <Spin spinning={loading} fullscreen />
      <div className="border-b-2 py-2">
        <span className="capitalize text-2xl">{path?.split("-").join(" ")}</span>
      </div>
      <div className="flex justify-end text-end gap-2 py-4">
        <Button type="primary" onClick={handleSaveButton}>
          Save
        </Button>

        <Button onClick={handleResetButton}>Reset</Button>
      </div>
      <div>
        <QuillEditor value={cmsContent} onChange={onChange} />
      </div>
    </div>
  );
};

export default CmsPage;
