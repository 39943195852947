import React, { useEffect, useState } from "react";
import CreateJobPostDrawer from "../globalComponents/CreateJobPostDrawer";
import { PlusOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { Button, List, Pagination, Space, Switch } from "antd";
import { createNewJob, deleteJob, getJobList, updateJob, updateJobPriority, updateHighlightingStatus, updateJobActiveStatus } from "../../helpers/JobApi";
import toast from "react-hot-toast";
import { confirmModalRef } from "../globalComponents/ConfirmModal";
import { Reorder } from "framer-motion";
import { convertUTCtoIST } from "../../utils/utils";
import { NavLink } from "react-router-dom";
const JobPost = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [isDraggable, setIsDraggable] = useState(false);
  const [defaultJobDetail, setDefaultJobDetail] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentJobs, setCurrentJobs] = useState([]);
  // const pageSize = 9;
  const handlePageChange = (e, size) => {
    setPageSize(size);
    setPage(e);
  };

  useEffect(() => {
    const indexOfLastJob = page * pageSize;
    const indexOfFirstJob = indexOfLastJob - pageSize;
    setCurrentJobs(jobList?.slice(indexOfFirstJob, indexOfLastJob));
  }, [page, jobList, pageSize]);

  // const handleSwitchChange = async (jobId, checked) => {
  //   const highlighting = checked ? 'true' : 'false';
  //   try {
  //     await updateHighlightingStatus(jobId, highlighting);
  //     setJobList(prevJobs =>
  //       prevJobs.map(job =>
  //         job.job_Id === jobId ? { ...job, highlighting } : job
  //       )
  //     );
  //   } catch (error) {
  //     console.error('There was an error updating the highlighting status!', error);
  //   }
  // };
  const handleSwitchChange = async (jobId, checked) => {
    const highlighting = checked ? "true" : "false";
    const prevJobs = [...jobList];
    setJobList((prevJobs) => prevJobs.map((job) => (job.job_Id === jobId ? { ...job, highlighting } : job)));

    try {
      await updateHighlightingStatus(jobId, highlighting);
    } catch (error) {
      setJobList(prevJobs);
    }
  };

  const handleActiveChange = async (jobId, checked) => {
    const active = checked ? "true" : "false";
    const prevJobs = [...jobList];
    setJobList((prevJobs) => prevJobs.map((job) => (job.job_Id === jobId ? { ...job, active } : job)));

    try {
      await updateJobActiveStatus(jobId, active);
    } catch (error) {
      setJobList(prevJobs);
    }
  };

  const getJobListFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const jobListResult = await getJobList();
      setJobList(jobListResult.map((element, index) => ({ ...element, index: index + 1 })));
      // console.log(jobListResult);
      // setJobList(jobListResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    let toastBox = toast;
    if (defaultJobDetail) {
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await updateJob({ ...values, jobId: defaultJobDetail?.job_Id });
        console.log(postJobApplicationResult);
        toast.success(`Job updated!`, {
          id: toastBox,
        });
        await getJobListFunc();
        setOpen(!open);
        setDefaultJobDetail(null);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    } else
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await createNewJob(values);
        console.log(postJobApplicationResult);
        toast.success(`Application submitted!`, {
          id: toastBox,
        });
        await getJobListFunc();
        setOpen(!open);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
  };

  const handleRemoveJob = async (jobId) => {
    confirmModalRef.current.open("This job will be deleted permanently!", "Are you sure?", async () => {
      let toastBox = toast;
      try {
        await deleteJob({ jobId: jobId });
        toast.success("Job deleted!", {
          id: toastBox,
        });
        getJobListFunc();
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    });
  };

  useEffect(() => {
    getJobListFunc();
  }, []);

  const handleSetPriority = () => {
    setIsDraggable(false);
    const newJobList = jobList.map((element, index) => ({ ...element, priority: jobList?.length - index }));
    setJobList(newJobList);
    updatePriorityFunc(newJobList);
  };

  const updatePriorityFunc = async (newJobList) => {
    let toastBox = toast;
    try {
      const updatePriorityResult = await updateJobPriority(newJobList);
      toast.success(`Priority updated!`, {
        id: toastBox,
      });
      console.log(updatePriorityResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  const handleEditJob = (job_Id) => {
    const selectedJobDetail = jobList.find((element) => element.job_Id === job_Id);
    setDefaultJobDetail(selectedJobDetail);
    setOpen(true);
  };

  return (
    <div>
      <CreateJobPostDrawer
        open={open}
        defaultJobDetail={defaultJobDetail}
        setOpen={(bool) => {
          if (!bool) setDefaultJobDetail(null);
          setOpen(bool);
        }}
        onFinish={(val) => onFinish(val)}
      />
      <div className="w-full">
        <div className="w-full text-end">
          <Button className="capitalize" size="large" type="primary" onClick={() => setOpen(!open)}>
            <div className="gap-1 flex items-center">
              <PlusOutlined />
              Create new post
            </div>
          </Button>
        </div>
        <div>
          <div className="border-b-2 w-full py-4 flex items-center justify-between">
            <span className="text-2xl font-medium capitalize">Job lists</span>
            {!isDraggable ? (
              <Button type="default" onClick={() => setIsDraggable(true)}>
                Change Priority
              </Button>
            ) : (
              <div>
                <Space>
                  <Button type="default" danger onClick={() => setIsDraggable(false)}>
                    Cancel
                  </Button>
                  <Button type="primary" className="bg-green-500" onClick={() => handleSetPriority()}>
                    Confirm
                  </Button>
                </Space>
              </div>
            )}
          </div>
          {isDraggable ? (
            <>
              <List
                itemLayout="horizontal"
                loading={loading}
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 8,
                }}
              >
                <Reorder.Group values={jobList} onReorder={setJobList}>
                  {jobList.map((item) => (
                    <Reorder.Item key={item.job_Id} value={item} className="bg-white border px-2 mt-2 rounded-md shadow">
                      <List.Item
                      // actions={[
                      //   <button type="button" key="list-loadmore-edit" className="text-amber-500" onClick={() => handleEditJob(item.job_Id)}>
                      //     edit
                      //   </button>,
                      //   <button type="button" key="list-loadmore-more" className="text-red-600" onClick={() => handleRemoveJob(item.job_Id)}>
                      //     remove
                      //   </button>,
                      // ]}
                      >
                        <List.Item.Meta
                          title={<span>{item.job_name}</span>}
                          description={
                            <div>
                              <span className="border-r pr-2">Salary: {item.salary_range}</span>
                              <span className="border-r px-2">Exp: {item.experience_range}</span>
                              <span className="border-r px-2">Category: {item.job_category}</span>
                              <span className="border-r px-2">Type: {item.job_type_name}</span>
                              <span className=" px-2">Location: {item.job_location}</span>
                              <span className="px-2">Candidates: {item.job_location}</span>
                              {/* <span className=" px-2">Created on: {convertUTCtoIST(item.created_at)}</span> */}
                            </div>
                          }
                        />
                      </List.Item>
                    </Reorder.Item>
                  ))}
                </Reorder.Group>
              </List>
            </>
          ) : (
            <>
              <List
                itemLayout="horizontal"
                loading={loading}
                dataSource={currentJobs}
                // pagination={{
                //   onChange: (page) => {
                //     console.log(page);
                //   },
                //   pageSize: 10,
                // }}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <div key={item.job_Id}>
                        <Space direction="horizontal">
                          <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} checked={item.active === "true"} onChange={(checked) => handleActiveChange(item.job_Id, checked)} />
                        </Space>
                      </div>,
                      <div key={item.job_Id} className="min-w-40">
                        <div className="flex flex-row w-full">
                          <span className="flex-1 text-left">{item.highlighting === "true" ? "Trending" : "Not Trending"}</span>
                          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={item.highlighting === "true"} onChange={(checked) => handleSwitchChange(item.job_Id, checked)} />
                        </div>
                      </div>,
                      <button type="button" key="list-loadmore-edit" className="text-amber-500 text-lg" onClick={() => handleEditJob(item.job_Id)}>
                        <EditOutlined />
                      </button>,
                      <button type="button" key="list-loadmore-more" className="text-red-600 text-lg" onClick={() => handleRemoveJob(item.job_Id)}>
                        <DeleteOutlined />
                      </button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<span>{item.index}.</span>}
                      title={<span>{item.job_name}</span>}
                      description={
                        <div className="">
                          <span className="border-r pr-2">Salary: {item.salary_range}</span>
                          <span className="border-r px-2">Exp: {item.experience_range}</span>
                          <span className="border-r px-2">Category: {item.job_category}</span>
                          <span className="border-r px-2">Type: {item.job_type_name}</span>
                          <span className="border-r px-2">Location: {item.job_location}</span>
                          <NavLink className="px-2" to={`/job-applications?job=${item.job_Id}`}>
                            Job Applications: {item.candidates}
                          </NavLink>
                          {/* <span className=" px-2">Created on: {convertUTCtoIST(item.created_at)}</span> */}
                          {/* <span className=" px-2">Created on: {convertUTCtoIST(item.created_at)}</span> */}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
              <Pagination size="default" defaultCurrent={1} total={jobList?.length} onChange={handlePageChange} showSizeChanger showQuickJumper showLessItems className="text-end mt-2" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPost;
