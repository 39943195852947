import "./App.css";
import { Toaster } from "react-hot-toast";
import AdminPanel from "./screens/AdminPanel";
import { HashRouter, Route, Routes } from "react-router-dom";
import AdminLogin from "./screens/adminLogin/AdminLogin";
import RequireAuth from "./screens/globalComponents/RequireAuth";
import ConfirmModal, { confirmModalRef } from "./screens/globalComponents/ConfirmModal";

function App() {
  // const { auth } = UseAuth();

  // const useHashRouting = () => {
  //   console.log(auth?.userActive);
  //   console.log("exec");
  //   if (auth?.userActive === "active") return true;
  //   else return false;
  // };
  // const Router = useHashRouting() ? HashRouter : BrowserRouter;
  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <ConfirmModal ref={confirmModalRef} />
      <HashRouter>
        <Routes>
          <Route path="/login" element={<AdminLogin />} />
          <Route element={<RequireAuth allowedRoles={["master"]} />}>
            <Route path="*" element={<AdminPanel />} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
