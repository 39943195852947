import React, { useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Space } from "antd";
import QuillEditor from "../../globalComponents/QuillEditor";

const CreateFaqPostDrawer = ({ open, setOpen, onFinish, defaultJobDetail }) => {
  const [form] = Form.useForm();

  const onClose = () => {
    form.setFieldsValue({
      title: null,
      blogImage: [],
      content: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (defaultJobDetail)
      form.setFieldsValue({
        title: defaultJobDetail?.title,
        content: defaultJobDetail?.content,
      });
    // form.setFieldsValue({
    //   categoryId: 1,
    //   locationId: 789,
    //   jobTypeId: 11,
    //   salaryRange: "2500-3000",
    //   expRange: "2-3",
    //   title: "defaultJobDetail?.job_name",
    //   companyName: "defaultJobDetail?.company_name",
    //   gender: "both",
    //   logo: [],
    //   overview: "defaultJobDetail?.overview",
    //   description: "defaultJobDetail?.description",
    //   extra: "defaultJobDetail?.extra",
    //   skill: ["test", "test2"],
    //   benefit: ["test", "test2"],
    //   responsibility: ["test", "test2"],
    //   countryId: 1,
    //   nationalityId: [1],
    // });
  }, [defaultJobDetail]);

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <>
      <Drawer
        title="Create a new FAQ"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
            overflowX: "visible",
          },
        }}
        placement="right"
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        {open && (
          <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={"optional"}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter FAQ title",
                    },
                  ]}
                >
                  <Input placeholder="Please enter FAQ title" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="content"
                  label="Content"
                  rules={[
                    {
                      required: true,
                      message: "Please write your FAQ content",
                    },
                  ]}
                >
                  <QuillEditor />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </>
  );
};
export default CreateFaqPostDrawer;
