import React, { useEffect, useState } from "react";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { Button, List, Pagination, Space, Switch } from "antd";
import toast from "react-hot-toast";
import { confirmModalRef } from "../globalComponents/ConfirmModal";
import CreateBlogPostDrawer from "./components/CreateBlogPostDrawer";
import { createNewBlog, deleteBlog, getBlogList, updateBlog, updateBlogActiveStatus, updateBlogHighlightingStatus } from "../../helpers/BlogApi";
import { API_URL, APP_JOB_BLOG_URL } from "../../helpers/AppApiUrls";
import { convertUTCtoIST } from "../../utils/utils";

const BlogPosts = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [defaultBlogDetail, setDefaultBlogDetail] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentJobs, setCurrentJobs] = useState([]);
  // const pageSize = 9;
  const handlePageChange = (e, size) => {
    setPageSize(size);
    setPage(e);
  };

  useEffect(() => {
    const indexOfLastJob = page * pageSize;
    const indexOfFirstJob = indexOfLastJob - pageSize;
    setCurrentJobs(blogList?.slice(indexOfFirstJob, indexOfLastJob));
  }, [page, blogList, pageSize]);

  const handleActiveChange = async (blogId, checked) => {
    const active = checked ? "true" : "false";
    const prevJobs = [...blogList];
    setBlogList((prevJobs) => prevJobs.map((blog) => (blog.id === blogId ? (active === "false" ? { ...blog, active, highlighting: "false" } : { ...blog, active }) : blog)));

    try {
      if (active === "false") await updateBlogHighlightingStatus(blogId, "false");
      await updateBlogActiveStatus(blogId, active);
    } catch (error) {
      setBlogList(prevJobs);
    }
  };

  const handleSwitchChange = async (blogId, checked) => {
    const highlighting = checked ? "true" : "false";
    const prevJobs = [...blogList];
    setBlogList((prevJobs) => prevJobs.map((blog) => (blog.id === blogId ? (highlighting === "true" ? { ...blog, highlighting, active: "true" } : { ...blog, highlighting }) : blog)));

    try {
      if (highlighting === "true") await updateBlogActiveStatus(blogId, "true");
      await updateBlogHighlightingStatus(blogId, highlighting);
    } catch (error) {
      setBlogList(prevJobs);
    }
  };

  const handleRemoveBlog = async (blogId) => {
    confirmModalRef.current.open("This blog will be deleted permanently!", "Are you sure?", async () => {
      let toastBox = toast;
      try {
        await deleteBlog({ blogId: blogId });
        toast.success("Admin deleted!", {
          id: toastBox,
        });
        getBlogListFunc();
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    });
  };

  const getBlogListFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const blogListResult = await getBlogList();
      console.log(blogListResult);
      setBlogList(blogListResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    let toastBox = toast;
    if (defaultBlogDetail) {
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await updateBlog({ ...values, blog_id: defaultBlogDetail?.id });
        console.log(postJobApplicationResult);
        toast.success(`Job updated!`, {
          id: toastBox,
        });
        await getBlogListFunc();
        setOpen(!open);
        setDefaultBlogDetail(null);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
    } else
      try {
        console.log("Received values of form: ", values);
        const postJobApplicationResult = await createNewBlog(values);
        console.log(postJobApplicationResult);
        toast.success(`Application submitted!`, {
          id: toastBox,
        });
        await getBlogListFunc();
        setOpen(!open);
      } catch (error) {
        toast.error(`${error}`, {
          id: toastBox,
        });
      }
  };

  const handleEditBlog = (blog_Id) => {
    const selectedBlogDetail = blogList.find((element) => element.id === blog_Id);
    setDefaultBlogDetail(selectedBlogDetail);
    setOpen(true);
  };

  useEffect(() => {
    getBlogListFunc();
  }, []);

  return (
    <div>
      <CreateBlogPostDrawer
        open={open}
        defaultJobDetail={defaultBlogDetail}
        setOpen={(bool) => {
          if (!bool) setDefaultBlogDetail(null);
          setOpen(bool);
        }}
        onFinish={(val) => onFinish(val)}
      />
      <div className="w-full">
        <div className="w-full text-end">
          <Button className="capitalize" size="large" type="primary" onClick={() => setOpen(!open)}>
            <div className="gap-1 flex items-center">
              <PlusOutlined />
              Create new blog
            </div>
          </Button>
        </div>
        <div>
          <div className="border-b-2 w-full py-4 flex items-center justify-between">
            <span className="text-2xl font-medium capitalize">Blog lists</span>
          </div>
          <List
            itemLayout="horizontal"
            loading={loading}
            dataSource={currentJobs}
            // pagination={{
            //   onChange: (page) => {
            //     console.log(page);
            //   },
            //   pageSize: 8,
            // }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <div key={item.id}>
                    <Space direction="horizontal">
                      <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} checked={item.active === "true"} onChange={(checked) => handleActiveChange(item.id, checked)} />
                    </Space>
                  </div>,
                  <div key={item.id} className="min-w-40">
                    <Space direction="horizontal">
                      <span>{item.highlighting === "true" ? "Highlighted" : "Not Highlighted"}</span>
                      <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={item.highlighting === "true"} onChange={(checked) => handleSwitchChange(item.id, checked)} />
                    </Space>
                  </div>,
                  <button type="button" key="list-loadmore-edit" className="text-amber-500" onClick={() => handleEditBlog(item.id)}>
                    edit
                  </button>,
                  <button type="button" key="list-loadmore-more" className="text-red-600" onClick={() => handleRemoveBlog(item.id)}>
                    remove
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div className="flex items-center gap-4">
                      <img src={`${API_URL}${APP_JOB_BLOG_URL}/${item.blog_image_name}`} alt="blog-image" width={100} className="shadow-lg rounded-xl" />
                      <Space direction="vertical">
                        <span className="text-xl font-medium">{item.title}</span>
                        <span className="">Created on: {convertUTCtoIST(item.created_at)}</span>
                      </Space>
                    </div>
                  }
                  description={<div></div>}
                />
              </List.Item>
            )}
          />
          <Pagination size="default" defaultCurrent={1} total={blogList?.length} onChange={handlePageChange} showSizeChanger showQuickJumper showLessItems className="text-end mt-2" />
        </div>
      </div>
    </div>
  );
};

export default BlogPosts;
