import { Card, Divider, Space } from "antd";
import React from "react";

const StatCard = ({ title, bgInnerColor, IconComponent, bgOuterColor, count }) => {
  return (
    <Card style={{ width: 250 }} bordered={false} className={`${bgOuterColor} p-4 rounded`}>
      <div className={`${bgInnerColor} aspect-square flex justify-center max-w-16 mx-auto items-center rounded-full p-0 mb-4`}>
        <IconComponent />
      </div>
      <h2 className="text-center mb-2">{title}</h2>
      <Divider />
      <p className="text-center text-lg font-semibold">{count}</p>
    </Card>

    // <Card
    //   title={
    //     <div className="py-3 justify-center text-center">
    //       <Space direction="vertical">
    //         <div className="p-4 rounded-full border">
    //           <IconComponent />
    //         </div>

    //         <span>{title}</span>
    //       </Space>
    //     </div>
    //   }
    //   style={{ width: 300 }}
    //   bordered={false}
    //   className={`${bgColor}`}
    // >
    //   <p>
    //     <IconComponent /> 100
    //   </p>
    // </Card>
  );
};

export default StatCard;
